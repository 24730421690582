// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

import * as Turbo from "@hotwired/turbo"
Turbo.start()

import { cable } from "@hotwired/turbo-rails"

import LocalTime from "local-time"
LocalTime.start()

import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'

const application = Application.start()

const controllers = import.meta.glob('../javascript/controllers/**/*_controller.js', { eager: true })
registerControllers(application, controllers)

import $ from 'jquery'

$(document).on("turbo:load", () => {
  $('a[rel=searchVideos]').on('click', function() {
    $("div.videoSearch").toggle();
    return false;
  });
})

import '../javascript/elements/emotes-badges'
import '../javascript/elements/chat-utils'
import '../javascript/elements/chat'
